

























.preview {
  flex: 1 1 auto;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;

  .preview-inner {
    .preview-block {
      width: 640px;
      background: #FFF;
      padding: 20px;
      box-shadow: 0px 0px 4px #ababab;
      border-radius: 2px;
      text-align: initial;
    }
  }
}
